<template>
 <div id="login">
    <van-overlay style="z-index: 998;" :show="show">
        <div style="width:100%;height: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
            <van-loading type="spinner" color="#1989fa" />
            <div style="color:#fff;padding-top:20px;">登录中,请稍后</div>
        </div>
    </van-overlay>
        <!-- <div class="mask"></div> -->
        <!-- <div class="back" v-on:click="backClick"><img class="icon" src="../assets/icon/back.png"/></div> -->
        <div v-if="isMobile" class="login-form">
             <div class="form-group" style="text-align: center;">
                <img class="logo" src="@/assets/logo_beltoo.jpg"/>
            </div>
            <div class="form-title">
                <span class="title">GPT-4</span>
                <span class="subtitle">新用户登录后将自动创建账号</span>
            </div>
            <!-- <van-form @submit="onSubmit">
            <van-cell-group inset>
                <van-field
                v-model="username"
                name="用户名"
                label="用户名"
                placeholder="用户名"
                :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                v-model="password"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码"
                :rules="[{ required: true, message: '请填写密码' }]"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                提交
                </van-button>
            </div>
            </van-form> -->
            <div class="form-group">
                <input type="number" class="form-input" v-model="account" placeholder="请输入手机号"/>
            </div>
            <div class="form-group" style="display:flex;">
                <input type="password" class="form-input" style="border-radius:2.8vh 0px 0px 2.8vh;;border-right:0"  v-model="code" placeholder="请输入验证码" maxlength="100">
                <button class="bt code" :style="{background:(canGetCode?'#1989FA':'#ccc')}" v-on:click="sendCode">{{codeText}}</button>
            </div>
            <!-- <div class="form-group">
                <input type="text" class="form-input" v-model="company" placeholder="请输入您的企业名称"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-input" v-model="accountName" placeholder="请输入您的称呼"/>
            </div> -->
             <div class="form-group agreement">
                <van-checkbox v-model="agreementChecked" icon-size="16px"></van-checkbox>
                 <!-- <input type="checkbox" v-model="agreementChecked"/> -->
                 <label class="text">注册或登录表示同意平台</label><a href="/agreement" class="link">用户协议</a>
            </div>
            <!-- <div wx:if="isInWeXin" class="form-group" style="display: flex;justify-content: center;" v-on:click="wxLogin">
              <img src="@/assets/icon/WX.png" style="width:10vw;height:10vw;"/>
            </div> -->
            <div class="form-group" style="text-align:center;margin-top:0px;">
                 <div class="btn" v-on:click="login">登录</div>
            </div>
        </div>
        <div v-else class="pc-warpper">
            <a href="https://ai.beltoo.com" target="blank" style="position:fixed;top:5%;right:10%;color:#395def;font-size: 18px;font-weight: 500;z-index: 999;">贝兔官网-></a>
            <img class="wave" src="@/assets/wave.png" title=""/>
            <div class="container">
                <div class="img">
                    <img src="@/assets/bg.svg"/>
                </div>
                <div class="login-content">
                    <form action="">
                        <img src="@/assets/logo_beltoo.jpg"/>
                        <h2 class="title">欢迎使用GPT-4</h2>
                        <div class="input-div one">
                            <!-- <div class="i">
                                <i icon="phone-o"></i>
                            </div> -->
                            <div class="div">
                                <!-- <h5>用户名</h5> -->
                                <input type="text" v-model="account"  placeholder="请输入手机号" class="input">
                            </div>
                        </div>
                        <div class="input-div pass">
                            <!-- <div class="i">
                                <van-icon name="phone-o" />
                            </div> -->
                            <div class="div">
                                <!-- <h5>密码</h5> -->
                                <input type="text" v-model="code"  placeholder="请输入验证码" class="input">
                                <button type="button" class="btn-pc btn-pc-mini" :style="{background:(canGetCode?'#1989FA':'#ccc')}" v-on:click="sendCode">{{codeText}}</button>
                            </div>
                        </div>
                        <!-- <a href="#">忘记密码?</a> -->
                        <input type="button" class="btn-pc" v-on:click="login" value="登 录">
                        <div style="display: flex;justify-content: center;align-items:center;">
                            <van-checkbox v-model="agreementChecked" icon-size="16px"></van-checkbox>
                            <!-- <input type="checkbox" v-model="agreementChecked"/> -->
                            <label style="color:#666;font-size: 14px;margin-left: 10px;">注册或登录表示同意平台</label><a href="/agreement" style="color:#38d39f;font-size: 14px;">用户协议</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- <div class="footer">
            <div class="back icon-kaistart-arrows-left width-19" v-on:click="back"></div>
        </div> -->
    </div>
</template>
<script>
import http from '../utils/http'
// import { Form, Field, CellGroup } from 'vant';
import { Checkbox, CheckboxGroup,showNotify,Loading,Overlay,Icon } from 'vant';
export default {
    name: 'login',
    components: {
    //   [Form.name]: Form,
      [Overlay.name]: Overlay,
      [Loading.name]: Loading,
      [Checkbox.name]: Checkbox,
      [CheckboxGroup.name]: CheckboxGroup,
    },
    computed:{

    },
    data () {
        return {
            isMobile: false,
            show:false,
            appId:'wxe39df9727d3d3782',
            appSecret:'9cd6de7ee9fe3d05c4790a5b144e10c2',
            // appId:'wx6024f19787e302d7',
            // appSecret:'385c33c92c3455d98238d6ae5759f41c',
            loginWay:'scope',
            codeText:'获取验证码',
            canGetCode:true,//获取中
            account:'',
            company:'',
            accountName:'',
            code:'',
            timers:30,
            agreementChecked:false,
            isInWeXin:false
        }
    },
    methods: {
        wxLogin:function(){
            if(!this.agreementChecked){
                showNotify({ type: 'warning', message: '请阅读并同意用户协议' });
                // this.message.info('请阅读并同意用户协议');
                return;
            }
            // var redirectUriWx='https://m.keyuexue.cn/login';
            var redirectUriWx='https://m.chatgpt-web-client.cn/login';
            // this.wxToken();
            let url = encodeURIComponent(redirectUriWx);  // 注意一定要encodeURIComponent
            var state=1;
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&&state=${state}#wechat_redirect';`
            // wx.updateAppMessageShareData({ 
            //     title: '测试', // 分享标题
            //     desc: '测试', // 分享描述
            //     link: '测试', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //     imgUrl: '测试', // 分享图标
            //     success: function () {
            //         console.log('分享成功')
            //     // 设置成功
            //     }
            // })
        },
        backClick:function(){
            this.$router.push({name:'chat'});
        },
        login: function () {
            if(!this.agreementChecked){
                showNotify({ type: 'warning', message: '请阅读并同意用户协议' });
                return;
            }
            if(!this.account){
                showNotify({ type: 'warning', message: '请输入手机号' });
                return;
            }
            if(!this.code){
                showNotify({ type: 'warning', message: '请输入验证码' });
                return;
            }
            // if(!this.company){
            //     showNotify({ type: 'warning', message: '请输入企业名称' });
            //     return;
            // }
            // if(!this.accountName){
            //     showNotify({ type: 'warning', message: '请输入账号' });
            //     return;
            // }
            var channel=this.$store.state.user.channel;
            var share=this.$store.state.user.share;
            this.show=true;
            this.$store.dispatch('user/login',{Phone:this.account,Code:this.code,Channel:channel,Share:share,Company:this.company,Name:this.accountName,success:(result)=>{
                    // this.$store.dispatch('user/getUserInfo');
                    this.show=false;
                    this.$router.push({name:'chat'});
                },
                error:(msg)=>{
                    this.show=false;
                    showNotify({ type: 'danger', message:'登录失败'+msg });
                }
            });
        },
        timer: function () {
                if (this.timers > 0) {
                    this.codeText=this.timers;
                    this.timers--;
                }
                else{
                    this.codeText="获取验证码";
                    this.canGetCode=true;
                }
        },
        sendCode:function(){
            if(!this.canGetCode){
                return;
            }
            if(!this.account){
                showNotify({ type: 'warning', message: '请输入手机号' });
                return;
            }
            http.get("/api/auth/sendcode?phone="+this.account,(data)=>{
                if (data.Success) {
                    this.timers=30;
                    this.canGetCode=false;
                    setInterval(this.timer, 1000);
                }
                else{
                    alert('登录失败'+data.Message);
                }
            },(e)=>{
                console.log(e);
            });
        },
        back: function () {
            this.$router.push({ name: 'index'});
        },
        wxToken(){
            
        },
        getWeChatConfig(redirectUriWx){
            return new Promise((resove,reject)=>{
                http.post("/Api/Wechat/Public/Share",{
                    appID:this.appId,
                    url:redirectUriWx,
                },(data)=>{
                    if (data.Success) {
                        resove(data.Data);
                    }
                    else{
                        resove(data.Message);
                    }
                },(e)=>{
                    resove(e);
                });
            });
        },
        getCode () {
            let url = location.search;
            let theRequest = new Object()
            if (url.indexOf("?") != -1) {
                let str = url.substr(1)
                let strs = str.split("&")
                for(let i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                }
            }
            return theRequest;
        },
        getIsMobile() {
            let userAgentInfo = navigator.userAgent;
            let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
            let getArr = Agents.filter(i => userAgentInfo.includes(i));
            return getArr.length ? true : false;
        },
    },
    created: function () {
        //this.loadProfit();
    },
    mounted: function () {//dom已渲染
        //如果来自微信回调
        // alert(JSON.stringify(this.getCode()))
        var that=this;
        this.isMobile = this.getIsMobile();
        window.onresize = function () {
            that.isMobile = that.getIsMobile();
        }
        var ua = navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //在微信中打
            //document.querySelector('.zfb').style.display="none";
            this.isInWeXin=true;
        }
        if(this.$route.query.code){
            http.post("/Api/Wechat/Public/UserInfo",{
                appID:this.appId,
                code:this.$route.query.code,
            },(data)=>{
                if (data.Success) {
                    data.Data.success=(result)=>{
                        this.message.info('微信登录成功');
                        this.$store.dispatch('user/getUserInfo');
                        //判断路由记录跳转到之前的页面
                        this.$router.push({ name: 'personal'})
                    };
                    data.Data.error=(msg)=>{
                         this.message.info('微信登录异常');
                    }
                    this.$store.dispatch('user/setLoginInfo',data.Data);
                }
                else{
                    console.log(data);
                }
            },(e)=>{
                this.message.info('微信登录异常');
            });
        }
    },
}
</script>
<style lang="css" scoped>
   @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");
   *{
       font-family: "Helvetica Neue",helvetica,arial,sans-serif;
   }
   #login{
        background:#fff;
        background-size: cover;
   }
   #login .logo{
        height: 18vh;
        border-radius: 2vw;
   }
   #login .mask{
        height: 100vh;
        width: 100vw;
        background: rgba(0,0,0,0);
        position: absolute;
        z-index: 1;
   }
   .back{
        position: absolute;
        top: 0;
        z-index: 999;
        width: 13.3vw;
        height: 13.3vw;
        /* background: red; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .back .icon{
        height: 16px;
    }
   .login-form{
        /* position: absolute; */
        padding:10px 30px 30px 30px;
        /* height: 100vh; */
        /* z-index: 2; */
   }
   .form-title{
        padding: 0px 0;
        font-size: 14px;
        color: #333;
        display: flex;
        flex-direction: column;
   }
   .form-title .title{
        font-weight: bold;
        font-size: 2rem;
        padding: 0px 0 30px 0;
        text-align: center;
   }
   .form-title .subtitle{
        font-size: 14px;
        color: #666;
        padding-left:10px;
   }
   .codebtn{
       width: 99px;
   }
   .form-input{
        outline-style: none;
        border: 1px solid #eee;
        border-radius:1.4rem;
        box-shadow: none;
        padding: 1.4rem 0;
        height: 1rem;
        width: 100%;
        color: #666;
        font-weight: 500;
        font-size: 1rem;
        text-indent: 1rem;
        -webkit-appearance: none;
        box-sizing: border-box;
   }
   .form-input:focus{
        border-color: #1989FA;
        outline: 0;
    }
   .form-group{
        width: 100%;
        padding: 2.0vh 0px;
        height: auto;
    }
    .form-control{
        font-size:14px;
        vertical-align: middle;
        color:#333;
        padding: 2.0vh;
        background: #fff;
    }
.agreement{
    display: flex;
    align-items: center;
}
.agreement .text{
    font-size: 14px;
    margin-left:10px;
    color: #333;
}
.agreement .link{
    font-size: 14px;
    color: #1989FA;
    font-weight: bold;
}
.bt {
     display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #1989FA;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    border-radius:2.8vh;
    text-align: center;
}
.code{
    border-radius: 0 2.8vh 2.8vh 0;
    width:140px;
    box-sizing: border-box;
    border: 1px solid #1989FA;
}
.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1989FA;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    font-size: 14px;
    font-weight: 700;
    padding: 2.0vh 0;
    color: #fff;
    letter-spacing: 0;
    border-radius: 1.0vh;
    text-align: center;
}
.bt.disabled {
    background-color: #d8d7d8;
    color: #989998
}

.pc-warpper{
}

.wave{
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: 0;
}

.container{
    height: 95vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
    padding: 0 2rem;
}

.img{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.img img{
    z-index: 1;
}
.login-content{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
    z-index: 2;
}

.img img{
	width: 500px;
}

form{
	width: 360px;
}

.login-content img{
    height: 160px;
}

.login-content h2{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.login-content .input-div{
	position: relative;
    display: grid;
    /* grid-template-columns: 7% 93%; */
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one{
	margin-top: 0;
}

.i{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i{
	transition: .3s;
}

.input-div > div{
    position: relative;
	height: 45px;
    display: flex;
    flex-direction: row;
}

.input-div > div > h5{
	position: absolute;
	left: 10px;
	top: 0%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}

.input-div:before, .input-div:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #38d39f;
	transition: .4s;
}

.input-div:before{
	right: 50%;
}

.input-div:after{
	left: 50%;
}

.input-div.focus:before, .input-div.focus:after{
	width: 50%;
}

.input-div.focus > div > h5{
	top: -5px;
	font-size: 15px;
}

.input-div.focus > .i > i{
	color: #38d39f;
}

.input-div > div > input{
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}
.input-div > div > input::placeholder{
    position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}
.input-div.pass{
	margin-bottom: 4px;
}

a{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}

a:hover{
	color: #38d39f;
}

.btn-pc{
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}
.btn-pc-mini{
    height: 30px;
    border-radius: 15px;
    width: 150px;
    font-size: 14px;
}
.btn-pc:hover{
	background-position: right;
}


@media screen and (max-width: 1050px){
	.container{
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px){
	form{
		width: 290px;
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}

	.img img{
		width: 400px;
	}
}

@media screen and (max-width: 900px){
	.container{
		grid-template-columns: 1fr;
	}

	.img{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}

</style>
